import React, { useEffect, useRef } from 'react';
import { ArrowRight, Share2, Terminal, X } from 'lucide-react';

// Types remain the same
type FeatureTab = {
  title: string;
  content: string;
  icon: React.ReactNode;
};

// Modal Component remains the same
const Modal = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  // ... Modal implementation remains the same
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50" />
      <div
        ref={modalRef}
        className="relative bg-white rounded-lg shadow-xl max-w-lg w-full p-3 mx-4"
        role="dialog"
        aria-modal="true"
      >
        {children}
      </div>
    </div>
  );
};

// WaitlistForm Component remains the same
const WaitlistForm = ({ onClose }: { onClose: () => void }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const formData = new FormData(e.currentTarget);
    const data = {
      name: formData.get('name') as string,
      email: formData.get('email') as string,
    };

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log('Form submitted:', data);
      onClose();
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
      >
        <X className="w-6 h-6" aria-hidden="true" />
        <span className="sr-only">Close</span>
      </button>

      <h2 className="text-3xl font-bold text-gray-700 mb-6 text-center">Join Our Waiting List</h2>
      <p className="text-lg text-gray-600 mb-2 text-center">
        Be among the first to experience the future of development workflow automation.
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            Full Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-green-400 focus:border-green-400"
            required
            disabled={isSubmitting}
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-green-400 focus:border-green-400"
            required
            disabled={isSubmitting}
          />
        </div>

        {error && <p className="text-red-600 text-sm">{error}</p>}

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-green-400 text-white py-2 rounded-lg hover:bg-green-500 transition-all duration-150 ease-in-out flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? (
            <>
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Submitting...</span>
            </>
          ) : (
            <>
              Join the Waiting List
              <ArrowRight className="w-4 h-4" aria-hidden="true" />
            </>
          )}
        </button>
      </form>
    </div>
  );
};

// Updated Hero Section
const HeroSection: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <section className="relative bg-white">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: 'url(/api/placeholder/1920/1080)' }}
        aria-hidden="true"
      />
      <div className="relative max-w-7xl mx-auto px-6 py-22 flex flex-col items-center text-center">
        <h1 className="text-5xl font-bold text-gray-700 leading-tight mb-6 max-w-4xl">
          Transform how your team share build artifacts
        </h1>

        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-green-400 text-white px-8 py-2 rounded-lg hover:bg-green-500 transition-all duration-150 ease-in-out flex items-center gap-2"
        >
          Join the Waiting List
          <ArrowRight className="w-4 h-4" aria-hidden="true" />
        </button>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <WaitlistForm onClose={() => setIsModalOpen(false)} />
        </Modal>
      </div>
    </section>
  );
};

// Updated Features Tabs
const FeaturesTabs: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  const features: FeatureTab[] = [
    {
      title: 'Seamless Integration',
      content:
        'Use the CLI to integrate with your existing tools and services, and automate your development workflow.',
      icon: <Terminal className="w-8 h-8 text-green-400" aria-hidden="true" />,
    },
    {
      title: 'Team Collaboration',
      content:
        'Enhanced collaboration features that keep your team in sync, streamline reviews, and accelerate development cycles.',
      icon: <Share2 className="w-8 h-8 text-green-400" aria-hidden="true" />,
    },
  ];

  return (
    <section id="features" className="max-w-7xl mx-auto px-6 py-24">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-700 mb-2">
          Save and share build artifacts with ease
        </h2>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Our platform provides a seamless way to save and share build artifacts across your team.
        </p>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-8">
        <div className="md:w-1/3">
          <ul className="flex md:flex-col">
            {features.map((feature, index) => (
              <li key={index} className="mb-2">
                <button
                  className={`flex items-center w-full text-left p-4 rounded-lg transition-colors duration-150 ease-in-out ${activeTab === index
                    ? 'bg-green-50 text-green-600'
                    : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                    }`}
                  onClick={() => setActiveTab(index)}
                >
                  <div className="mr-4">{feature.icon}</div>
                  <span className="font-medium">{feature.title}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="md:w-2/3 mt-8 md:mt-0">
          <div className="bg-white p-8 rounded-xl shadow-xl">
            <h3 className="text-2xl font-bold text-gray-700 mb-2">{features[activeTab].title}</h3>
            <p className="text-gray-600 leading-relaxed mb-6">{features[activeTab].content}</p>
            <a
              href="#learn-more"
              className="text-green-400 hover:text-green-500 font-medium flex items-center"
            >
              Learn More
              <ArrowRight className="w-4 h-4 ml-1" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

// Landing Page Component
const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
          body {
            font-family: 'Inter', sans-serif;
          }
        `}
      </style>

      <main className="flex-grow">
        <HeroSection />
        <FeaturesTabs />
      </main>
    </div>
  );
};

export default LandingPage;
