import { XCircle } from 'lucide-react';

interface ErrorAlertProps {
  message: string;
  onDismiss?: () => void;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, onDismiss }) => {
  return (
    <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg mb-2">
      <div className="flex items-start">
        <div className="ml-3 flex-1">
          <p className="text-sm text-red-700">{message}</p>
        </div>
        {onDismiss && (
          <div className="ml-4 flex-shrink-0">
            <button onClick={onDismiss} className="text-red-500 hover:text-red-600">
              <XCircle size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default ErrorAlert;