import React, { useState, useEffect } from 'react';
import { Mail, Lock } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../onboarding/hooks/useAuth';
import { EndpointConfig, TokenService } from '../../lib/token';
import ResetPassword from './ResetPassword';
import { ErrorMessage } from '@/ui/Errors/ErrorMessage/ErrorMessage';
import { BackendError } from '@/models/errorTypes';
import VerificationStatus from '../onboarding/components/VerificationStatus';
import PasswordChangeForm from '../profile/PasswordChangeForm';

const LoginPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('team@gameforgelabs.com');
  const [password, setPassword] = useState('testerA$1');
  const [rememberMe, setRememberMe] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [validationError, setValidationError] = useState<BackendError | null>(null);

  const { getAccessToken, isLoading, authorizationError: error, clearError } = useAuth();
  const navigate = useNavigate();

  // Get verification status from URL params
  const verificationComplete = searchParams.get('verificationComplete') === 'true';
  const verificationPassword = searchParams.get('verificationPassword') === 'true';
  const verificationError = searchParams.get('error');

  useEffect(() => {
    // Handle verification errors from URL params
    if (verificationError) {
      let errorMessage: BackendError;
      switch (verificationError) {
        case 'invalid_token':
          errorMessage = {
            type: 'app-store/invalid-verification-token',
            status: 400,
            title: 'Invalid Verification Token',
            details: 'The verification link is invalid. Please request a new verification email.',
          };
          break;
        case 'expired_token':
          errorMessage = {
            type: 'app-store/expired-verification-token',
            status: 400,
            title: 'Expired Verification Token',
            details: 'The verification link has expired. Please request a new verification email.',
          };
          break;
        default:
          errorMessage = {
            type: 'app-store/verification-error',
            status: 400,
            title: 'Verification Error',
            details: 'An error occurred during email verification. Please try again.',
          };
      }
      setValidationError(errorMessage);
    }
  }, [verificationError]);

  const createValidationError = (message: string): BackendError => ({
    type: 'app-store/validation-error',
    status: 400,
    title: 'Validation Error',
    details: message,
  });

  const validateForm = () => {
    if (!email) {
      setValidationError(createValidationError('Please enter your email address'));
      return false;
    }
    if (!password) {
      setValidationError(createValidationError('Please enter your password'));
      return false;
    }
    if (!email.includes('@')) {
      setValidationError(createValidationError('Please enter a valid email address'));
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    clearError();
    setValidationError(null);

    if (!validateForm()) {
      return;
    }

    try {
      const tokenResponse = await getAccessToken(email, password);

      if (rememberMe) {
        localStorage.setItem('auth_token', tokenResponse.access_token);
      } else {
        sessionStorage.setItem('auth_token', tokenResponse.access_token);
      }

      TokenService.getInstance().setTokens(tokenResponse);
      navigate('/workspace');
    } catch (err) {
      console.error('Login failed');
    }
  };

  const handleResendVerification = async () => {
    try {
      await fetch(`${EndpointConfig.baseUrl}/api/v1/onboarding/signup/resend-verification`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      setValidationError({
        type: 'app-store/verification-sent',
        status: 200,
        title: 'Verification Email Sent',
        details:
          'A new verification email has been sent to your inbox. Please check your email and follow the verification link.',
      });
    } catch (err) {
      console.error('Failed to resend verification email:', err);
      setValidationError({
        type: 'app-store/verification-error',
        status: 400,
        title: 'Error Sending Verification',
        details: 'Failed to send verification email. Please try again later.',
      });
    }
  };

  // Show either validation error or API error
  const displayError = validationError || error;

  const isVerificationTokenExpired = displayError?.type === 'app-store/expired-verification-token';
  const isVerificationTokenInvalid = displayError?.type === 'app-store/invalid-verification-token';
  const isVerificationRequired = displayError?.type === 'app-store/verification-required';
  const isVerificationSent = displayError?.type === 'app-store/verification-sent';

  const showVerificationStatus =
    isVerificationRequired || isVerificationTokenExpired || isVerificationTokenInvalid;


  if(verificationPassword) {
    return <PasswordChangeForm />
  } 

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-md mx-auto px-6 py-24">
        {isForgotPassword ? (
          <ResetPassword onBack={() => setIsForgotPassword(false)} />
        ) : (
          <>
            <div className="text-center mb-2">
              <div className="text-2xl font-bold text-gray-700">
                Releases<span className="text-green-400">.</span>
              </div>
              <h1 className="text-3xl font-bold text-gray-700 mt-8 mb-3">Welcome back</h1>
              <p className="text-gray-600">Sign in to manage your releases</p>
            </div>

            {verificationComplete && (
              <div className="mb-6 rounded-lg bg-green-50 border border-green-200 p-4">
                <h3 className="text-green-800 font-semibold mb-1">
                  Email verification successful!
                </h3>
                <p className="text-green-700">
                  Your email has been verified. Please sign in to continue.
                </p>
              </div>
            )}

            <div className="bg-white rounded-xl shadow-lg p-8">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full pl-12 pr-4 py-2 border-2 rounded-lg focus:ring-2 focus:ring-green-400 focus:border-green-400"
                    placeholder="Work email"
                    required
                  />
                  <Mail className="absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
                </div>

                <div className="relative">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full pl-12 pr-4 py-2 border-2 rounded-lg focus:ring-2 focus:ring-green-400 focus:border-green-400"
                    placeholder="Password"
                    required
                  />
                  <Lock className="absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
                </div>

                <div className="flex items-center justify-between">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className="rounded border-gray-300 text-green-400 focus:ring-green-400"
                    />
                    <span className="ml-2 text-sm text-gray-600">Remember me</span>
                  </label>
                  <button
                    type="button"
                    onClick={() => setIsForgotPassword(true)}
                    className="text-sm text-green-400 hover:text-green-500"
                  >
                    Forgot password?
                  </button>
                </div>

                {showVerificationStatus && (
                  <VerificationStatus
                    email={email}
                    isVerificationRequired={isVerificationRequired}
                    isVerificationTokenExpired={isVerificationTokenExpired}
                    isVerificationTokenInvalid={isVerificationTokenInvalid}
                    onResendVerification={handleResendVerification}
                  />
                )}

                {/* Show error message only if it's not a verification-related error */}
                {displayError && !showVerificationStatus && !isVerificationSent && (
                  <ErrorMessage
                    error={displayError}
                    onDismiss={() => {
                      clearError();
                      setValidationError(null);
                    }}
                  />
                )}

                {/* Show success message for verification email sent */}
                {isVerificationSent && (
                  <div className="rounded-lg bg-green-50 border border-green-200 p-4">
                    <h3 className="text-green-800 font-semibold mb-1">Verification email sent</h3>
                    <p className="text-green-700">
                      Please check your email for the verification link.
                    </p>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full bg-green-400 text-white px-6 py-2 rounded-lg hover:bg-green-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Signing in...' : 'Sign in'}
                </button>
              </form>
              { /* 
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-700">Or continue with</span>
                  </div>
                </div>

                <button
                  type="button"
                  className="mt-4 w-full border-2 border-gray-200 rounded-lg px-6 py-2 flex items-center justify-center gap-2 hover:bg-gray-50 transition-colors"
                  onClick={() => (window.location.href = '/auth/github')}
                >
                  <Github className="h-5 w-5" />
                  <span>GitHub</span>
                </button>
              </div>*/}
            </div>

            <p className="mt-8 text-center text-gray-600">
              Don't have an account?{' '}
              <a href="/signup" className="text-green-400 hover:text-green-500 font-medium">
                Sign up now
              </a>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
