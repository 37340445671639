import React, { useRef, useState } from 'react';
import { Upload, X, Check, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';

export interface FileUploadStatus {
  file: File;
  status: 'idle' | 'uploading' | 'success' | 'error';
  progress: number;
  errorMessage?: string;
}

interface FileUploaderProps {
  onFilesSelected: (files: File[]) => void;
  onFileRemove: (file: File) => void;
  fileStatuses: FileUploadStatus[];
  isUploading?: boolean;
  showUploadButton?: boolean;
  onUploadClick?: () => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onFilesSelected,
  onFileRemove,
  fileStatuses,
  isUploading = false,
  showUploadButton = true,
  onUploadClick,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    onFilesSelected(selectedFiles);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    onFilesSelected(droppedFiles);
  };

  return (
    <div className="space-y-4">
      <div
        className={`h-64 w-full rounded-xl border-2 border-dashed 
          ${isDragging ? 'border-green-400 bg-green-50' : 'border-gray-300 bg-gray-50'}
          hover:border-gray-400 flex flex-col items-center justify-center p-3 
          transition-colors duration-200`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="w-16 h-16 bg-green-50 rounded-lg flex items-center justify-center mb-2">
          <Upload className="w-8 h-8 text-green-400" />
        </div>
        <p className="text-gray-700">Drag and drop your files here</p>
        <p className="text-sm text-gray-700 mt-2 mb-2">or</p>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          className="hidden"
          multiple
        />
        <Button
          onClick={() => fileInputRef.current?.click()}
          variant="secondary"
          className="border border-gray-300 text-gray-700 hover:bg-gray-50"
        >
          Browse Files
        </Button>
      </div>

      {fileStatuses.length > 0 && (
        <div className="space-y-4">
          {fileStatuses.map(({ file, status, progress, errorMessage }) => (
            <div
              key={file.name}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center flex-1 mr-4">
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900">{file.name}</p>
                  <p className="text-sm text-gray-700">
                    {(file.size / (1024 * 1024)).toFixed(2)} MB
                  </p>
                  {status === 'error' && (
                    <p className="text-sm text-red-500">{errorMessage}</p>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-4">
                {status === 'uploading' && (
                  <>
                    <div className="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-green-400 transition-all duration-300"
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                    <Loader2 className="w-5 h-5 text-green-400 animate-spin" />
                  </>
                )}
                {status === 'success' && (
                  <div className="w-5 h-5 bg-green-50 rounded-full flex items-center justify-center">
                    <Check className="w-4 h-4 text-green-400" />
                  </div>
                )}
                {(status === 'idle' || status === 'error') && (
                  <button
                    onClick={() => onFileRemove(file)}
                    className="text-gray-400 hover:text-gray-700"
                  >
                    <X className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>
          ))}

          {showUploadButton && (
            <div className="flex justify-end mt-4">
              <Button
                onClick={onUploadClick}
                disabled={isUploading || fileStatuses.length === 0}
                className="bg-green-400 text-white hover:bg-green-500 disabled:opacity-50"
              >
                {fileStatuses.some((s) => s.status === 'error')
                  ? 'Retry Failed Uploads'
                  : 'Upload All Files'}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUploader;