import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import {
    FileText, Image, Code, File, FileArchive,
    Terminal, Coffee, Download,
    AlertCircle
} from 'lucide-react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { useFormatters } from '@/hooks/useFormatters';
import { useFileDownloads } from './hooks/useFileDownload';
import { Alert, AlertDescription } from '@/components/ui/alert';

export interface BuildFile {
    fileId: string;
    fileName: string;
    fileExtension: string;
    fileSize: number;
    filePath: string;
}

export interface BuildFileActions {
    onDownload?: (file: BuildFile) => void;
    onShare?: (file: BuildFile) => void;
}


export const getFileExtensionType = (extension: string = ''): {
    icon: React.ElementType;
    color?: string;
} => {
    const ext = extension.toLowerCase();

    switch (true) {
        case /^(jpg|jpeg|png|gif|svg|webp)$/.test(ext):
            return { icon: Image };
        case /^(js|jsx|ts|tsx)$/.test(ext):
            return { icon: Code, color: 'text-yellow-500' };
        case /^(java|class)$/.test(ext):
            return { icon: Coffee, color: 'text-red-500' };
        case /^(sh|bash|zsh)$/.test(ext):
            return { icon: Terminal, color: 'text-green-500' };
        case /^(zip|rar|7z|tar|gz|jar|war)$/.test(ext):
            return { icon: FileArchive, color: 'text-blue-500' };
        case /^(txt|pdf|doc|docx|md)$/.test(ext):
            return { icon: FileText };
        default:
            return { icon: File };
    }
};

interface ActionButtonProps {
    icon: React.ElementType;
    label: string;
    onClick: () => void;
    disabled?: boolean;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
    icon: Icon,
    label,
    onClick,
    disabled = false
}) => (
    <TooltipProvider>
        <Tooltip>
            <TooltipTrigger asChild>
                <button
                    onClick={onClick}
                    disabled={disabled}
                    className="p-2 text-gray-700 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    <Icon className="w-4 h-4" />
                </button>
            </TooltipTrigger>
            <TooltipContent>
                <p>{label}</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
);


interface BuildFileCardProps {
    file: BuildFile;
    workspaceSlug: string;
    projectSlug: string;
    actions?: BuildFileActions;
    className?: string;
}

export const BuildFileCard: React.FC<BuildFileCardProps> = ({
    file,
    workspaceSlug,
    projectSlug,
    className = ''
}) => {
    const { formatFileSize } = useFormatters();
    const { icon: FileIcon, color } = getFileExtensionType(file.fileExtension);
    const { downloadFile, isDownloading, error } = useFileDownloads(workspaceSlug, projectSlug);

    const handleDownload = async () => {
        await downloadFile(file);
    };



    return (
        <div className="space-y-2">
            <Card className={`w-full hover:bg-gray-50 transition-colors duration-200 ease-in-out border-gray-200 ${className}`}>
                <CardContent className="p-4">
                    <div className="flex items-center space-x-4">
                        <div className={`text-gray-700 ${color || ''}`}>
                            <FileIcon className="w-6 h-6" />
                        </div>
                        <div className="flex-1 min-w-0">
                            <div className="flex items-center justify-between">
                                <p className="text-sm font-medium text-gray-900 truncate">
                                    {file.fileName}
                                </p>
                                <div className="flex items-center space-x-1">
                                    <span className="text-xs text-gray-700 font-mono mr-4">
                                        {formatFileSize(file.fileSize)}
                                    </span>
                                    <ActionButton
                                        icon={Download}
                                        label="Download file"
                                        onClick={handleDownload}
                                        disabled={isDownloading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            {error && (
                <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>
                        Failed to download file: {error.message}
                    </AlertDescription>
                </Alert>
            )}
        </div>
    );
};

// components/BuildFileList.tsx
interface BuildFileListProps {
    files: BuildFile[];
    actions?: BuildFileActions;
    className?: string;
    projectSlug: string;
    workspaceSlug: string;
}

export const BuildFileList: React.FC<BuildFileListProps> = ({
    files,
    actions,
    workspaceSlug,
    projectSlug,
    className = ''
}) => {
    return (
        <div className={`grid gap-4 ${className}`}>
            {files.map((file) => (
                <BuildFileCard
                    key={file.fileId}
                    file={file}
                    actions={actions} workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
            ))}
        </div>
    );
};

