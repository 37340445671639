import { useState } from 'react';
import { Lock, Loader, CheckCircle } from 'lucide-react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface PasswordChangeFormProps {
    onSuccess?: () => void;
    onError?: (error: string) => void;
}

const PasswordChangeForm = ({ onSuccess, onError }: PasswordChangeFormProps) => {
    const [passwordForm, setPasswordForm] = useState({
        newPassword: '',
        confirmPassword: '',
    });
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [localError, setLocalError] = useState<string | null>(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');


    const handlePasswordChange = async () => {
        setLocalError(null);

        if (passwordForm.newPassword !== passwordForm.confirmPassword) {
            setLocalError("New passwords don't match");
            onError?.("New passwords don't match");
            return;
        }

        if (passwordForm.newPassword.length < 8) {
            setLocalError("New password must be at least 8 characters long");
            onError?.("New password must be at least 8 characters long");
            return;
        }

        setIsChangingPassword(true);

        try {
            const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/user/security/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token ? token: TokenService.getInstance().getAccessToken()}`,
                },
                body: JSON.stringify({
                    newPassword: passwordForm.newPassword,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to change password');
            }

            setShowSuccess(true);
            setTimeout(() => setShowSuccess(false), 3000);
            onSuccess?.();
            if(token) {
                navigate('/login');
                return;
            }

            setPasswordForm({
                newPassword: '',
                confirmPassword: '',
            });
        } catch (error) {
            const errorMessage = 'Failed to change password. Please check your current password and try again.';
            setLocalError(errorMessage);
            onError?.(errorMessage);
        } finally {
            setIsChangingPassword(false);
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Lock className="w-5 h-5" />
                    Change Password
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
                <div className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="new-password">New Password</Label>
                        <Input
                            id="new-password"
                            type="password"
                            value={passwordForm.newPassword}
                            onChange={(e) => setPasswordForm({ ...passwordForm, newPassword: e.target.value })}
                        />
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="confirm-password">Confirm New Password</Label>
                        <Input
                            id="confirm-password"
                            type="password"
                            value={passwordForm.confirmPassword}
                            onChange={(e) => setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })}
                        />
                    </div>

                    {localError && (
                        <Alert variant="destructive">
                            <AlertDescription>{localError}</AlertDescription>
                        </Alert>
                    )}

                    {showSuccess && (
                        <Alert>
                            <CheckCircle className="w-4 h-4" />
                            <AlertDescription>Password changed successfully</AlertDescription>
                        </Alert>
                    )}

                    <Button
                        onClick={handlePasswordChange}
                        disabled={isChangingPassword}
                        className="w-full bg-green-400 text-white px-6 py-2 rounded-lg hover:bg-green-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {isChangingPassword ? (
                            <>
                                <Loader className="w-4 h-4 mr-2 animate-spin" />
                                Changing Password...
                            </>
                        ) : (
                            <>
                                <Lock className="w-4 h-4 mr-2" />
                                Change Password
                            </>
                        )}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

export default PasswordChangeForm;