import { useState, useEffect } from 'react';
import {
  Search,
  SearchIcon,
} from 'lucide-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import BuildUploaderButton from '../../ui/FileUploader/BuildUploader';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent } from '@/components/ui/card';
import { Build } from '@/models/build';
import { BuildDetails } from '@/models/buildDetails';
import Pagination from '@/ui/Paging/Pagination';
import Navigation from '@/ui/Navigating/Navigation';
import BuildForm from './BuildForm';
import { useBuildsSearch } from './hooks/useBuildSearch';
import { useBuildMutations } from './hooks/useBuildMutationsHook';
import BuildTable from './BuildTable';
import LoadingSpinner from '@/ui/LoadingSpinner/LoadingSpinner';
import SlideOver from '@/ui/SlideOver/SlideOver';
import { useSlideOver } from '@/ui/SlideOver/hooks/useSlideOver';
import { SlideOverType } from '@/ui/SlideOver/SlideOverType';
import { BuildManager } from './BuildManager';
import { usePersistentState } from '@/hooks/usePersistentState';
import { ProjectWithFavorite } from '@/models/project';
import { Permissions } from '@/models/permission';



export default function BuildPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { workspaceSlug, projectSlug } = useParams();

  const queryParams = new URLSearchParams(location.search);
  const [searchQuery, setSearchQuery] = useState<string>(queryParams.get('search') || '');
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(queryParams.get('page') || '1', 10),
  );
  const [selectedTags, setSelectedTags] = useState<string[]>(
    queryParams.get('tags')?.split(',').filter(Boolean) || [],
  );

  const [selectedBuildForEdit, setSelectedBuildForEdit] = useState<BuildDetails | null>(null);
  const [selectedBuildForFiles, setSelectedBuildForFiles] = useState<Build | null>(null);

  const { setActivePanel } = useSlideOver();

  const itemsPerPage = 30;


  const { builds, isLoading, buildSearchError, fetchBuilds } = useBuildsSearch({
    workspaceSlug,
    projectSlug,
    currentPage,
    itemsPerPage,
    searchQuery,
    selectedTags
  });


  const {
    isLoading: isSubmitting,
    deleteBuild,
    editBuild,
  } = useBuildMutations({
    workspaceSlug,
    projectSlug,
    onSuccess: fetchBuilds,
  });

  const [currentProject] = usePersistentState<ProjectWithFavorite | null>('currentProject', null);



  useEffect(() => {
    const newParams = new URLSearchParams();
    if (searchQuery) newParams.set('search', searchQuery);
    if (currentPage > 1) newParams.set('page', currentPage.toString());
    if (selectedTags.length > 0) newParams.set('tags', selectedTags.join(','));

    const search = newParams.toString();
    const newUrl = `${location.pathname}${search ? `?${search}` : ''}`;
    navigate(newUrl, { replace: true });
  }, [searchQuery, currentPage, selectedTags, location.pathname, navigate]);


  const handleSearch = () => fetchBuilds();

  const handleCopyBuildLink = async (buildId: string) => {
    const buildUrl = `${window.location.origin}${location.pathname}?search=${buildId}`;
    try {
      await navigator.clipboard.writeText(buildUrl);
    } catch (err) {
      console.error('Failed to copy build URL:', err);
    }
  };

  const handleTagClick = (tag: string) => {
    setSelectedTags((prev) => {
      if (prev.includes(tag)) return prev.filter((t) => t !== tag);
      return [...prev, tag];
    });
    setCurrentPage(1);
  };




  if (buildSearchError || currentProject === null) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Card>
          <CardContent className="pt-6">
            <div className="text-center">
              <h2 className=" text-md text-navy-200 mb-2">Error Loading Builds</h2>
              <p className="text-gray-700 mb-2">{buildSearchError?.message}</p>
              <Button
                onClick={handleSearch}
                className="bg-green-400 hover:bg-green-500 text-white"
              >
                Try Again
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  const canEdit = currentProject.permissions.includes(Permissions.BuildEdit);
  const canDelete = currentProject.permissions.includes(Permissions.BuildDelete);


  return (
    <div className="min-h-screen bg-white">
      <Navigation workspaceSlug={workspaceSlug} projectSlug={projectSlug} activeRoute={'builds'} />
      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4 mb-6">
          <Button
            disabled={!currentProject.permissions.includes(Permissions.BuildCreate)}
            onClick={() => setActivePanel(SlideOverType.BUILD_UPLOAD)}
            className="bg-green-400 text-white px-2 py-2 text-sm rounded-lg hover:bg-green-500 transition-colors font-medium"
          >Upload new Build</Button>
          <div className="relative flex-1 max-w-md">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700 w-5 h-5" />
              <Input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
                className="pl-10 border-gray-300 focus:border-green-400 focus:ring-green-300"
              />
            </div>
          </div>

          <Button
            variant="outline"
            onClick={handleSearch}
            className="border-gray-300 text-gray-700 hover:bg-gray-50"
          >
            <SearchIcon className="w-4 h-4 mr-2" />
            {isLoading ? 'Loading...' : 'Search'}
          </Button>
        </div>

        {(selectedTags.length > 0) && (
          <div className="mb-6 flex flex-wrap gap-2">
            {selectedTags.map((tag) => (
              <Badge key={tag} variant="secondary" className="bg-green-50 text-green-600">
                {tag}
                <button
                  onClick={() => handleTagClick(tag)}
                  className="ml-2 text-green-400 hover:text-green-600"
                >
                  ×
                </button>
              </Badge>
            ))}
          </div>
        )}

        <Card>
          <div className="container mx-auto p-0">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <BuildTable
                builds={builds}
                onCopyLink={handleCopyBuildLink}
                onEditBuild={canEdit ? (build) => {
                  setSelectedBuildForEdit(build as BuildDetails);
                  setActivePanel(SlideOverType.EDIT_BUILD);
                } : undefined}
                onDeleteBuild={canDelete ? (buildId) => deleteBuild(buildId) : undefined}
                onTagClick={handleTagClick}
                onViewFiles={(build) => {
                  setSelectedBuildForFiles(build);
                  setActivePanel(SlideOverType.BUILD_FILES);
                }}
              />
            )}

          </div>

          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={builds.length}
            onPageChange={setCurrentPage}
          />
        </Card>
      </div>


      <SlideOver title='Edit Build' width='w-350' type={SlideOverType.EDIT_BUILD}>
        {selectedBuildForEdit && (
          <BuildForm
            buildDetails={selectedBuildForEdit}
            onSubmit={(e) => {
              e.preventDefault();
              setSelectedBuildForFiles(null);
              setSelectedBuildForEdit(null);
              selectedBuildForEdit.buildId && editBuild(selectedBuildForEdit.buildId, selectedBuildForEdit);
              setActivePanel(SlideOverType.NONE);
            }}
            onChange={(updatedBuild) => {
              setSelectedBuildForEdit(updatedBuild)
            }}
            submitLabel="Update Build"
            isSubmitting={isSubmitting}
          />
        )}
      </SlideOver>

      <SlideOver title='Build details' type={SlideOverType.BUILD_FILES} width='w-450'>
        {selectedBuildForFiles && workspaceSlug && projectSlug &&  currentProject && <BuildManager build={selectedBuildForFiles} workspaceSlug={workspaceSlug} projectSlug={projectSlug} project={currentProject}/>}
      </SlideOver>
      <SlideOver title='Upload new build' type={SlideOverType.BUILD_UPLOAD} width='w-450'>
        <BuildUploaderButton workspaceSlug={workspaceSlug!} projectSlug={projectSlug!} />
      </SlideOver>
    </div>

  );
}
