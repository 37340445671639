import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import Navigation from '@/ui/Navigating/Navigation';

import { Button } from "@/components/ui/button";
import DeleteConfirmationModal from '@/ui/DeleteConfirmationModal/DeleteConfirmationModal';
import ProjectCard from './ProjectCard';
import ProjectForm from './ProjectForm';
import { useFavorites } from './hooks/useFavorites';
import { useProjectDelete } from './hooks/useProjectDelete';
import { useProjectFetch } from './hooks/useProjectFetch';
import LoadingSpinner from '@/ui/LoadingSpinner/LoadingSpinner';
import { useProjectCreate } from './hooks/useProjectCreate';
import { ProjectWithFavorite } from '../../models/project';
import SlideOver from '@/ui/SlideOver/SlideOver';
import { SlideOverType } from '@/ui/SlideOver/SlideOverType';
import { useSlideOver } from '@/ui/SlideOver/hooks/useSlideOver';
import { Permissions } from '@/models/permission';
import { WorkspaceWithFavorite } from '@/models/workdpace';
import { usePersistentState } from '@/hooks/usePersistentState';

const ProjectDashboard: React.FC = () => {
  const { workspaceSlug, projectSlug } = useParams();
  const [selectedForEdit, setSelectedForEdit] = useState<ProjectWithFavorite | null>(null);
  const { toggleFavorite } = useFavorites();
  const { isLoading: isLoading, projects, setProjects, fetchProjects } = useProjectFetch(workspaceSlug!);
  const { formData, setFormData, handleSubmit, resetForm } = useProjectCreate({
    workspaceSlug: workspaceSlug!,
  });
  const [currentWorkspace] = usePersistentState<WorkspaceWithFavorite | null>('currentWorkspace', null);
  const { setActivePanel } = useSlideOver();


  const {
    isModalOpen: isDeleteModalOpen,
    setIsModalOpen: setIsDeleteModalOpen,
    selectedProject: deletingProject,
    isDeleting,
    handleDeleteClick,
    handleDelete,
  } = useProjectDelete({
    workspaceSlug: workspaceSlug!,
    onSuccess: () => fetchProjects()
  });

  const handleCreateSubmit = async () => {
    try {
      setSelectedForEdit(null);
      setActivePanel(SlideOverType.NONE);
      await handleSubmit(false, undefined, formData);
      // Refresh data with favorites
      await fetchProjects();

    } catch (error) {
      console.error('Error creating project:', error);
    }
  };


  const handleEditSubmit = async () => {
    try {
      await handleSubmit(true, selectedForEdit?.projectSlug, { ...formData, removeImage: !formData.image && !formData.imageUrl });
      setActivePanel(SlideOverType.NONE);
      // Refresh data with favorites
      await fetchProjects();
    } catch (error) {
      console.error('Error editing project:', error);
    }
  };

  const canCreate = currentWorkspace?.permissions.includes(Permissions.ProjectCreate);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation
        workspaceSlug={workspaceSlug!}
        projectSlug={projectSlug}
        activeRoute={'projects'}
      />

      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex justify-between items-start mb-2">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 mb-2"></h1>
            <p className="text-lg text-gray-600"></p>
          </div>
          <Button className="bg-green-500 hover:bg-green-600 text-white"
            disabled={!canCreate}
            variant="default" onClick={() => setActivePanel(SlideOverType.PROJECT_CREATE)}>
            <Plus className="w-4 h-4 mr-2" />
            Create Project
          </Button>
        </div>

        {isLoading && <LoadingSpinner />}
        <div className="space-y-4">
          {projects.map((project) => (
            <ProjectCard
              key={project.projectId}
              project={project}
              workspaceSlug={workspaceSlug!}
              onToggleFavorite={() => toggleFavorite(project.projectId, projects, setProjects)}
              onEdit={(project: ProjectWithFavorite) => {
                setSelectedForEdit(project);
                setFormData({
                  name: project.name,
                  description: project.description,
                  repository: project.repository,
                  image: project.image,
                  imageUrl: project.imageUrl
                });
                setActivePanel(SlideOverType.PROJECT_EDIT);
              }}
              onDelete={handleDeleteClick}
            />
          ))}
        </div>
      </div>

      <SlideOver title='Create Project' type={SlideOverType.PROJECT_CREATE} width='w-450'>
        <ProjectForm
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleCreateSubmit}
          onCancel={() => { }}
          submitLabel="Save"
          submitting={isLoading}
        />
      </SlideOver>


      <SlideOver title='Edit Project' type={SlideOverType.PROJECT_EDIT} width='w-450'>
        <ProjectForm
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleEditSubmit}
          onCancel={() => {
            setSelectedForEdit(null);
            resetForm();
          }}
          submitLabel="Save"
          submitting={isLoading}
        />
      </SlideOver>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete project"
        itemName={deletingProject?.projectSlug!}
        isDeleting={isDeleting}
        onConfirm={handleDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
    </div>
  );
};

export default ProjectDashboard;