import { useState } from "react";
import { EndpointConfig, TokenService } from "@/lib/token";
import { ProjectFormData } from "../../../models/project";


interface useProjectCreate {
    workspaceSlug: string;
}

export const useProjectCreate = ({ workspaceSlug }: useProjectCreate) => {
    const [formData, setFormData] = useState<ProjectFormData>({
        name: '',
        repository: '',
        description: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const resetForm = () => {
        setFormData({ name: '', repository: '', description: '', image: '', imageUrl: '' });
    };

    const handleSubmit = async (isEdit: boolean, projectSlug: string | undefined, formData: ProjectFormData) => {
        setIsLoading(true);

        try {
            const requestBody: ProjectFormData = {
                name: formData.name,
                description: formData.description,
                repository: formData.repository,
                image: formData.image || undefined,
                removeImage: formData.removeImage,
            };
            requestBody.image = formData.image;;

            let url = `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project`;
            let method = 'PUT';
            if (isEdit && projectSlug) {
                url = `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}`;
                method = 'POST';
            }

            const response = await fetch(
                url,
                {
                    method,
                    headers: {
                        'Authorization': `Bearer ${TokenService.getInstance().getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (response.ok) {
                resetForm();
            }
        } catch (error) {
            console.error('Failed to create project:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        formData,
        setFormData,
        isLoading,
        handleSubmit,
        resetForm,
    };
};