import { useState, useEffect } from 'react';
import {
  User as UserIcon,
  Settings,
  Shield,
  Menu,
  X,
  Loader,
  Save,
  CheckCircle,
} from 'lucide-react';
import { EndpointConfig, TokenService } from '@/lib/token';
import PasswordChangeForm from './PasswordChangeForm';

interface User {
  userId: string;
  email: string;
  displayName: string;
  avatarUrl: string;
  lastSeen: string;
  role: 'USER' | 'ADMIN';
  status: 'PENDING' | 'ACTIVE' | 'INACTIVE';
  origin: string;
  preferredLanguage: string;
  timezone: string;
  settings: string;
}

const UserSettingsScreen = () => {
  const [activeTab, setActiveTab] = useState<'profile' | 'preferences' | 'access'>('profile');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/user`, {
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user profile');
        }

        const userData = await response.json();
        setUser(userData);
      } catch (error) {
        setError('Failed to load user profile');
        console.error('Error fetching user profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSave = async () => {
    if (!user) return;
    setIsSaving(true);
    setError(null);

    try {
      const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/user/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
        },
        body: JSON.stringify({
          displayName: user.displayName,
          preferredLanguage: user.preferredLanguage,
          timezone: user.timezone,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const updatedUser = await response.json();
      setUser(updatedUser);
      setShowSaveSuccess(true);
      setTimeout(() => setShowSaveSuccess(false), 3000);
    } catch (error) {
      setError('Failed to save changes');
      console.error('Error saving user settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading || !user) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-green-400" />
      </div>
    );
  }

  if (error && !user) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-500 mb-2">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="text-green-400 hover:text-green-500 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  const tabs = [
    { id: 'profile' as const, label: 'Profile', icon: UserIcon },
    { id: 'preferences' as const, label: 'Preferences', icon: Settings },
    { id: 'access' as const, label: 'Access & Security', icon: Shield },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="border-b border-gray-300 bg-white/80 backdrop-blur-sm sticky top-0 z-20">
        <div className="max-w-4xl mx-auto px-3 py-2">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-3xl sm:text-5xl font-bold text-gray-700 leading-tight">
                Account Settings
              </h1>
              <p className="text-gray-600 mt-1 text-sm sm:text-base">
                <div className="mt-8 text-center text-sm text-gray-700">
                  <p>User ID: {user.userId}</p>
                </div>{' '}
                Last active: {new Date(user.lastSeen).toLocaleString()}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={handleSave}
                disabled={isSaving}
                className={`inline-flex items-center gap-2 px-6 py-2 rounded-lg transition-all duration-150 
                  ${isSaving ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-400 hover:bg-green-500'} 
                  text-white text-sm sm:text-base`}
              >
                {isSaving ? (
                  <Loader className="w-4 h-4 animate-spin" />
                ) : (
                  <Save className="w-4 h-4" />
                )}
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="lg:hidden p-2 text-gray-600 hover:bg-gray-50 rounded-lg transition-colors"
              >
                {isMobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </button>
            </div>
          </div>

          {/* Feedback Messages */}
          <div className="fixed top-4 right-4 z-50 space-y-2">
            {showSaveSuccess && (
              <div className="flex items-center gap-2 px-2 py-2 bg-green-100 text-green-600 rounded-lg shadow-lg">
                <CheckCircle className="w-4 h-4" />
                <span>Changes saved successfully</span>
              </div>
            )}
            {error && (
              <div className="flex items-center gap-2 px-2 py-2 bg-red-100 text-red-600 rounded-lg shadow-lg">
                <span>{error}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-6 py-6">
        <div className="bg-white rounded-xl shadow-sm">
          <div className="flex flex-col lg:flex-row">
            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
              <div
                className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
                onClick={() => setIsMobileMenuOpen(false)}
              />
            )}

            {/* Navigation Sidebar */}
            <div
              className={`
              lg:w-64 lg:static lg:block
              fixed inset-y-0 left-0 z-40 
              transform transition-transform duration-300 ease-in-out
              bg-white lg:bg-transparent
              ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
              border-r border-gray-300
            `}
            >
              <nav className="p-4 space-y-1">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => {
                      setActiveTab(tab.id);
                      setIsMobileMenuOpen(false);
                    }}
                    className={`w-full flex items-center gap-3 px-2 py-2 text-sm font-medium rounded-lg transition-colors ${activeTab === tab.id
                        ? 'bg-green-50 text-green-600'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      }`}
                  >
                    <tab.icon
                      className={`w-5 h-5 ${activeTab === tab.id ? 'text-green-600' : 'text-gray-400'
                        }`}
                    />
                    {tab.label}
                  </button>
                ))}
              </nav>
            </div>

            {/* Content Area */}
            <div className="flex-1 p-4 sm:p-3">
              {activeTab === 'profile' && (
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Display Name
                    </label>
                    <input
                      type="text"
                      value={user.displayName}
                      onChange={(e) => setUser({ ...user, displayName: e.target.value })}
                      className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-300 focus:border-green-400 transition-colors"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                    <input
                      type="email"
                      value={user.email}
                      disabled
                      onChange={(e) => setUser({ ...user, email: e.target.value })}
                      className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-300 focus:border-green-400 transition-colors"
                    />
                  </div>
                </div>
              )}

              {activeTab === 'preferences' && (
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Preferred Language
                    </label>
                    <select
                      value={user.preferredLanguage}
                      onChange={(e) => setUser({ ...user, preferredLanguage: e.target.value })}
                      className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-300 focus:border-green-400 transition-colors"
                    >
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="fr">French</option>
                      <option value="de">German</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Timezone</label>
                    <select
                      value={user.timezone}
                      onChange={(e) => setUser({ ...user, timezone: e.target.value })}
                      className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-300 focus:border-green-400 transition-colors"
                    >
                      <option value="UTC">UTC</option>
                      <option value="America/New_York">Eastern Time</option>
                      <option value="America/Los_Angeles">Pacific Time</option>
                      <option value="Europe/London">London</option>
                    </select>
                  </div>
                  {user.settings && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Additional Settings
                      </label>
                      <pre className="w-full px-2 py-2 border border-gray-300 rounded-lg bg-gray-50 font-mono text-sm overflow-auto">
                        {JSON.stringify(JSON.parse(user.settings), null, 2)}
                      </pre>
                    </div>
                  )}
                </div>
              )}

              {activeTab === 'access' && (
                <div className="space-y-6">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Role</label>
                    <div
                      className={`inline-flex items-center gap-2 px-3 py-1 rounded-full bg-green-100 text-green-600`}
                    >
                      <span className="w-2 h-2 rounded-full bg-current"></span>
                      <span className="font-medium">{user.role}</span>
                    </div>
                  </div>

                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Account Status
                    </label>
                    <div
                      className={`inline-flex items-center gap-2 px-3 py-1 rounded-full ${user.status === 'ACTIVE'
                          ? 'bg-green-100 text-green-600'
                          : user.status === 'PENDING'
                            ? 'bg-yellow-100 text-yellow-600'
                            : 'bg-red-100 text-red-600'
                        }`}
                    >
                      <span className="w-2 h-2 rounded-full bg-current"></span>
                      <span className="font-medium">{user.status}</span>
                    </div>
                  </div>

                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Last Active
                    </label>
                    <div className="text-gray-600">{new Date(user.lastSeen).toLocaleString()}</div>
                  </div>


                  {/* Add the new Password Change form */}
                  <PasswordChangeForm
                    onSuccess={() => {
                      setShowSaveSuccess(true);
                      setTimeout(() => setShowSaveSuccess(false), 3000);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsScreen;
