import { useState } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Database, Cloud, Loader2, ArrowRight, CheckCircle2 } from 'lucide-react';
import { useSlideOver } from '@/ui/SlideOver/hooks/useSlideOver';
import { useFetchWorkspaces } from '@/hooks/useWorkspaces';
import { SlideOverType } from '@/ui/SlideOver/SlideOverType';
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Badge } from "@/components/ui/badge";
import { Progress } from "@/components/ui/progress";

interface ManageWorkspaceProps {
    onUpdate?: () => void;
}

interface PricingTier {
    id: string;
    name: string;
    price: number;
    features: string[];
    recommended?: boolean;
}

const PRICING_TIERS: PricingTier[] = [
    {
        id: 'free',
        name: 'Developer',
        price: 0,
        features: ['5GB Storage', '2 Users', '2 Projects', 'Basic Support'],
    },
    {
        id: 'pro',
        name: 'Team',
        price: 5,
        features: ['200GB Storage', '10 Users', '10 Projects', 'Priority Support'],
        recommended: true
    },
    {
        id: 'enterprise',
        name: 'Enterprise',
        price: 100,
        features: ['1 TB Storage', 'Unlimited Users', 'Priority Support', 'Custom Contracts'],
    }
];

export default function ManageWorkspace({ onUpdate }: ManageWorkspaceProps) {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        storageType: 'managed',
        s3Config: {
            accessKeyId: '',
            secretAccessKey: '',
            bucket: '',
            region: 'us-east-1'
        },
        plan: PRICING_TIERS[0],
        billingCycle: 'monthly'
    });

    const { setActivePanel } = useSlideOver();
    const { createWorkspace, loading } = useFetchWorkspaces();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Form Data:', formData, 'Current Step:', currentStep);
        if (currentStep !== 3) {
            return;
        }

        if (!formData.name.trim()) {
            return;
        }

        const success = await createWorkspace({
            workspaceName: formData.name.trim(),
        });

        if (success) {
            onUpdate?.();
            setFormData(prev => ({ ...prev, name: '' }));
            setActivePanel(SlideOverType.NONE);

        }
    };
    return (
        <div className="space-y-8">
            <div className="border-b border-gray-300 pb-4">
                <Progress value={(currentStep / 3) * 100} className="mb-2 bg-gray-100" />
                <div className="flex justify-between text-sm text-gray-700">
                    <div className={currentStep >= 1 ? 'text-green-400 font-medium' : ''}>
                        1. Workspace Details
                    </div>
                    <div className={currentStep >= 2 ? 'text-green-400 font-medium' : ''}>
                        2. Storage Configuration
                    </div>
                    <div className={currentStep >= 3 ? 'text-green-400 font-medium' : ''}>
                        3. Plan Selection
                    </div>
                </div>
            </div>

            <form className="space-y-6">
                {currentStep === 1 && (
                    <div className="space-y-6">
                        <div>
                            <h2 className="text-xl font-bold text-gray-700 mb-1">Name your workspace</h2>
                            <p className="text-sm text-gray-700 mb-2">
                                This will be your team's home for projects and collaboration.
                            </p>
                        </div>

                        <div className="space-y-4">
                            <div className="space-y-2">
                                <Label htmlFor="workspace-name" className="text-gray-700">Workspace Name</Label>
                                <Input
                                    id="workspace-name"
                                    value={formData.name}
                                    onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                    placeholder="e.g., Engineering Team"
                                    className="h-10 border-gray-300 focus:border-green-400 focus:ring-green-300"
                                />
                            </div>
                        </div>
                    </div>
                )}

                {currentStep === 2 && (
                    <div className="space-y-6">
                        <div>
                            <h2 className="text-xl font-bold text-gray-700 mb-1">Choose storage type</h2>
                            <p className="text-sm text-gray-700 mb-2">
                                Select how you want to manage your workspace storage.
                            </p>
                        </div>

                        <RadioGroup
                            value={formData.storageType}
                            onValueChange={value => setFormData(prev => ({ ...prev, storageType: value }))}
                            className="gap-4"
                        >
                            <Card className={`border-2 ${formData.storageType === 'managed' ? 'border-green-400' : 'border-gray-300'} 
                                hover:border-green-400 transition-colors duration-150`}>
                                <CardContent className="p-4">
                                    <RadioGroupItem value="managed" id="managed" className="hidden" />
                                    <Label htmlFor="managed" className="flex items-start gap-4 cursor-pointer">
                                        <Cloud className="h-6 w-6 text-green-400 mt-1" />
                                        <div>
                                            <p className="font-medium text-gray-700">Managed Cloud Storage</p>
                                            <p className="text-sm text-gray-700">Let us handle your storage needs based on your subscription plan</p>
                                            <div className="flex gap-2 mt-2">
                                                <Badge variant="secondary" className="bg-gray-100 text-gray-700">Automatic Scaling</Badge>
                                                <Badge variant="secondary" className="bg-gray-100 text-gray-700">Managed Backups</Badge>
                                            </div>
                                        </div>
                                    </Label>
                                </CardContent>
                            </Card>

                            <Card className={`border-2 ${formData.storageType === 'self-managed' ? 'border-green-400' : 'border-gray-300'}
                                hover:border-green-400 transition-colors duration-150`}>
                                <CardContent className="p-4">
                                    <RadioGroupItem value="self-managed" id="self-managed" className="hidden" />
                                    <Label htmlFor="self-managed" className="flex items-start gap-4 cursor-pointer">
                                        <Database className="h-6 w-6 text-green-400 mt-1" />
                                        <div>
                                            <p className="font-medium text-gray-700">Self-Managed S3</p>
                                            <p className="text-sm text-gray-700">Use your own AWS S3 bucket and credentials</p>
                                            <div className="flex gap-2 mt-2">
                                                <Badge variant="secondary" className="bg-gray-100 text-gray-700">Full Control</Badge>
                                                <Badge variant="secondary" className="bg-gray-100 text-gray-700">Custom Configuration</Badge>
                                            </div>
                                        </div>
                                    </Label>
                                </CardContent>
                            </Card>
                        </RadioGroup>

                        {formData.storageType === 'self-managed' && (
                            <Card className="border-gray-300">
                                <CardContent className="p-4 space-y-4">
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="space-y-2">
                                            <Label htmlFor="accessKeyId" className="text-gray-700">Access Key ID</Label>
                                            <Input
                                                id="accessKeyId"
                                                value={formData.s3Config.accessKeyId}
                                                onChange={e => setFormData(prev => ({
                                                    ...prev,
                                                    s3Config: { ...prev.s3Config, accessKeyId: e.target.value }
                                                }))}
                                                className="border-gray-300 focus:border-green-400 focus:ring-green-300"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="secretKey" className="text-gray-700">Secret Access Key</Label>
                                            <Input
                                                id="secretKey"
                                                type="password"
                                                value={formData.s3Config.secretAccessKey}
                                                onChange={e => setFormData(prev => ({
                                                    ...prev,
                                                    s3Config: { ...prev.s3Config, secretAccessKey: e.target.value }
                                                }))}
                                                className="border-gray-300 focus:border-green-400 focus:ring-green-300"
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-2">
                                        <Label htmlFor="bucket" className="text-gray-700">S3 Bucket Name</Label>
                                        <Input
                                            id="bucket"
                                            value={formData.s3Config.bucket}
                                            onChange={e => setFormData(prev => ({
                                                ...prev,
                                                s3Config: { ...prev.s3Config, bucket: e.target.value }
                                            }))}
                                            className="border-gray-300 focus:border-green-400 focus:ring-green-300"
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                )}

                {currentStep === 3 && (
                    <div className="space-y-6">
                        <div>
                            <h2 className="text-xl font-bold text-gray-700 mb-1">Select a plan</h2>
                            <p className="text-sm text-gray-700 mb-2">
                                Choose the plan that best fits your team's needs.
                            </p>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                            {PRICING_TIERS.map((tier) => (
                                <Card
                                    key={tier.id}
                                    className={`relative border-2 ${formData.plan.id === tier.id ? 'border-green-400' : 'border-gray-300'}
                                        cursor-pointer hover:border-green-400 transition-colors duration-150`}
                                    onClick={() => setFormData(prev => ({ ...prev, plan: tier }))}
                                >
                                    {tier.recommended && (
                                        <div className="absolute -top-3 left-1/2 -translate-x-1/2">
                                            <Badge className="bg-green-400 text-white">Recommended</Badge>
                                        </div>
                                    )}
                                    <CardContent className="p-3">
                                        <div className="space-y-4">
                                            <div>
                                                <h3 className="font-bold text-lg text-gray-700">{tier.name}</h3>
                                                <div className="mt-2">
                                                    <span className="text-2xl font-bold text-gray-700">
                                                        ${tier.price}
                                                    </span>
                                                    {tier.price > 0 && (
                                                        <span className="text-gray-700">/month</span>
                                                    )}
                                                </div>
                                            </div>
                                            <ul className="space-y-3">
                                                {tier.features.map((feature, i) => (
                                                    <li key={i} className="flex items-center gap-2">
                                                        <CheckCircle2 className="h-4 w-4 text-green-400" />
                                                        <span className="text-sm text-gray-600">{feature}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                )}

                <div className="flex justify-between pt-6 border-t border-gray-300">
                    {currentStep > 1 && (
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => setCurrentStep(prev => prev - 1)}
                            className="border-gray-300 text-gray-700 hover:bg-gray-50"
                        >
                            Back
                        </Button>
                    )}
                    {currentStep < 3 ? (
                        <Button
                            type="button"
                            className="ml-auto bg-green-400 hover:bg-green-500 text-white transition-colors duration-150"
                            onClick={() => setCurrentStep(prev => prev + 1)}
                        >
                            Continue
                            <ArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    ) : (
                        <Button
                            className="ml-auto bg-green-400 hover:bg-green-500 text-white transition-colors duration-150"
                            disabled={loading}
                            type="button"
                            onClick={handleSubmit}
                        >
                            {loading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Creating...
                                </>
                            ) : (
                                'Create Workspace'
                            )}
                        </Button>
                    )}
                </div>
            </form>
        </div>
    );
}