import { Link } from 'react-router-dom';
import { ArrowLeft, Menu } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useState } from 'react';

interface NavigationProps {
  workspaceSlug?: string;
  projectSlug?: string;
  activeRoute?: 'workspace' | 'projects' | 'builds';
}

export default function Navigation({
  workspaceSlug,
  projectSlug,
  activeRoute = 'workspace',
}: NavigationProps): JSX.Element {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const routes = [
    {
      name: 'Workspace',
      path: `/workspace`,
      show: !!workspaceSlug,
    },
    {
      name: 'Projects',
      path: `/workspace/${workspaceSlug}/projects`,
      show: !!workspaceSlug,
    },
    {
      name: 'Builds',
      path: `/workspace/${workspaceSlug}/projects/${projectSlug}/builds`,
      show: !!workspaceSlug && !!projectSlug,
    },
  ];

  const IndexActiveRoute = routes.findIndex((route) => route.name.toLowerCase() === activeRoute);

  return (
    <div className="border-b border-gray-300">
      <div className="max-w-7xl mx-auto">
        <div className="px-6">
          <div className="flex items-center h-16">
            <Link
              to={routes[IndexActiveRoute - 1].path}
              className="flex items-center text-gray-700 hover:text-gray-600 group mr-8"
            >
              <ArrowLeft className="w-5 h-5 mr-2 text-gray-700 group-hover:text-gray-600" />
            </Link>

            <nav className="hidden md:flex space-x-8">
              {routes.map(
                (route) =>
                  route.show && (
                    <Link
                      key={route.name}
                      to={route.path}
                      className={`px-1 py-4 ${
                        activeRoute === route.name.toLowerCase()
                          ? 'text-green-400 border-b-2 border-green-400'
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      {route.name}
                    </Link>
                  ),
              )}
            </nav>

            <Button
              variant="ghost"
              size="icon"
              className="md:hidden ml-auto"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <Menu className="w-6 h-6" />
            </Button>
          </div>

          {isMobileMenuOpen && (
            <div className="md:hidden pb-4 space-y-2">
              {routes.map(
                (route) =>
                  route.show && (
                    <Link
                      key={route.name}
                      to={route.path}
                      className={`block py-2 ${
                        activeRoute === route.name.toLowerCase()
                          ? 'text-green-400'
                          : 'text-gray-600'
                      }`}
                    >
                      {route.name}
                    </Link>
                  ),
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
