import { useEffect, useState } from "react";
import { Project, ProjectWithFavorite } from "../../../models/project";
import { EndpointConfig, TokenService } from "@/lib/token";
import { useFavorites } from "./useFavorites";

export const useProjectFetch = (workspaceSlug: string) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [projects, setProjects] = useState<ProjectWithFavorite[]>([]);
    const { loadFavorites } = useFavorites(); // Add this line

    const fetchProjects = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                `${EndpointConfig.baseUrl}/api/v1/user/workspaces/${workspaceSlug}/projects`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
                    },
                },
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: Project[] = await response.json();
            const favorites = loadFavorites();

            const projectsWithFavorites = data
                .map((project) => ({
                    ...project,
                    isFavorite: favorites.includes(project.projectId),
                }))
                .sort((a, b) => {
                    if (a.isFavorite === b.isFavorite) return a.projectSlug.localeCompare(b.projectSlug);
                    return a.isFavorite ? -1 : 1;
                });

            setProjects(projectsWithFavorites);
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (workspaceSlug) {
            fetchProjects();
        }
    }, [workspaceSlug]);

    return {
        isLoading,
        projects,
        setProjects,
        fetchProjects,
    };
};