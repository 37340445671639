import { useState, useEffect } from 'react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { Build } from '@/models/build';

interface UseBuildsSearchProps {
    workspaceSlug?: string;
    projectSlug?: string;
    currentPage: number;
    itemsPerPage: number;
    searchQuery: string;
    selectedTags: string[];
}

interface UseBuildsSearchReturn {
    builds: Build[];
    isLoading: boolean;
    buildSearchError: Error | null;
    fetchBuilds: () => Promise<void>;
}

export function useBuildsSearch({
    workspaceSlug,
    projectSlug,
    currentPage,
    itemsPerPage,
    searchQuery,
    selectedTags,
}: UseBuildsSearchProps): UseBuildsSearchReturn {
    const [builds, setBuilds] = useState<Build[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [buildSearchError, setBuildSearchError] = useState<Error | null>(null);
    const fetchBuilds = async () => {
        if (!workspaceSlug || !projectSlug) return;

        setIsLoading(true);
        setBuildSearchError(null);

        try {
            const queryParams = new URLSearchParams({
                page: String(currentPage - 1),
                size: String(itemsPerPage),
            });

            if (searchQuery) queryParams.append('searchTerm', searchQuery);
            if (selectedTags.length > 0) queryParams.append('tags', selectedTags.join(','));


            const response = await fetch(
                `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build?${queryParams.toString()}`,
                {
                    headers: {
                        Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                },
            );

            if (!response.ok) throw new Error('Failed to fetch builds');
            const data = await response.json();
            setBuilds(data);
        } catch (err) {
            setBuildSearchError(err instanceof Error ? err : new Error('An error occurred'));
            console.error('Error fetching builds:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBuilds();
    }, [searchQuery, selectedTags]);

    return {
        builds,
        isLoading,
        buildSearchError,
        fetchBuilds,
    };
}