import React, { useRef, useState } from 'react';
import { Loader2, X, Image as ImageIcon, AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Card, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { ProjectFormData } from '../../models/project';

interface ProjectFormProps {
    formData: ProjectFormData;
    setFormData: (data: ProjectFormData) => void;
    onSubmit: () => Promise<void>;
    onCancel: () => void;
    submitLabel: string;
    submitting: boolean;
}

interface ImageValidationError {
    message: string;
    type: 'size' | 'dimensions' | 'format';
}

const MAX_FILE_SIZE = 50 * 1024; // 50KB
const ALLOWED_FORMATS = ['image/png', 'image/jpeg'];
const REQUIRED_DIMENSIONS = { width: 128, height: 128 };

const validateImage = async (file: File): Promise<ImageValidationError | null> => {
    if (file.size > MAX_FILE_SIZE) {
        return {
            type: 'size',
            message: `Image size must be less than 50KB. Current size: ${(file.size / 1024).toFixed(1)}KB`
        };
    }

    if (!ALLOWED_FORMATS.includes(file.type)) {
        return {
            type: 'format',
            message: 'Only PNG, JPEG, and WebP formats are allowed'
        };
    }

    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            if (img.width !== REQUIRED_DIMENSIONS.width || img.height !== REQUIRED_DIMENSIONS.height) {
                resolve({
                    type: 'dimensions',
                    message: `Image must be exactly ${REQUIRED_DIMENSIONS.width}x${REQUIRED_DIMENSIONS.height} pixels. Current dimensions: ${img.width}x${img.height}`
                });
            }
            resolve(null);
        };
        img.src = URL.createObjectURL(file);
    });
};

const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

const ProjectForm = ({
    formData,
    setFormData,
    onSubmit,
    onCancel,
    submitLabel,
    submitting
}: ProjectFormProps) => {
    const [imagePreview, setImagePreview] = useState<string | null>(formData.imageUrl || null);
    const [imageError, setImageError] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        setImageError(null);

        if (file) {
            const error = await validateImage(file);
            if (error) {
                setImageError(error.message);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                return;
            }

            try {
                const base64 = await convertImageToBase64(file);
                setImagePreview(URL.createObjectURL(file));
                setFormData({ ...formData, image: base64 });
            } catch (err) {
                setImageError('Failed to process image');
                console.error('Image processing error:', err);
            }
        }
    };

    const clearImageSelection = () => {
        setImagePreview(null);
        setImageError(null);
        setFormData({ ...formData, image: undefined, imageUrl: undefined });
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSubmit();
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
                <Label htmlFor="name">Project Name</Label>
                <Input
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter project name"
                    required
                />
            </div>

            <div className="space-y-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Enter project description"
                    className="min-h-[100px] resize-y"
                    required
                />
            </div>

            <div className="space-y-2">
                <Label htmlFor="repository">Repository URL</Label>
                <Input
                    id="repository"
                    name="repository"
                    type="url"
                    value={formData.repository}
                    onChange={handleInputChange}
                    placeholder="https://github.com/username/repository"
                />
            </div>

            <div className="space-y-2">
                <Label>Project Logo</Label>
                {imageError && (
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{imageError}</AlertDescription>
                    </Alert>
                )}
                <Card className="border-dashed">
                    <CardContent className="p-0">
                        {imagePreview ? (
                            <div className="relative w-32 h-32">
                                <img
                                    src={imagePreview}
                                    alt="Preview"
                                    className="w-full h-full object-cover rounded-lg"
                                />
                                <Button
                                    type="button"
                                    variant="secondary"
                                    size="icon"
                                    onClick={clearImageSelection}
                                    className="absolute -top-2 -right-2"
                                >
                                    <X className="w-4 h-4" />
                                </Button>
                            </div>
                        ) : (
                            <Button
                                type="button"
                                variant="ghost"
                                className="w-32 h-32 rounded-lg flex flex-col items-center justify-center gap-2 hover:bg-secondary"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <ImageIcon className="w-8 h-8 text-muted-foreground" />
                                <span className="text-xs text-muted-foreground text-center">
                                    Upload logo
                                    <br />
                                    (128x128 PNG)
                                    <br />
                                    Max: 50KB
                                </span>
                            </Button>
                        )}
                    </CardContent>
                </Card>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/png,image/jpeg,image/webp"
                    onChange={handleImageSelect}
                    className="hidden"
                />
            </div>

            <div className="flex justify-end gap-3 pt-4">
                <Button
                    type="button"
                    variant="ghost"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    className="bg-green-500 hover:bg-green-600 text-white" variant="default"
                    disabled={submitting}
                >
                    {submitting ? (
                        <>
                            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                            {submitLabel}...
                        </>
                    ) : (
                        submitLabel
                    )}
                </Button>
            </div>
        </form>
    );
};

export default ProjectForm;