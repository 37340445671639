import { useState, useEffect } from 'react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { Workspace, WorkspaceWithFavorite } from '@/models/workdpace';


const FAVORITES_KEY = 'workspace_favorites';

interface WorkspaceCreateData {
  workspaceName: string;
}

interface UseWorkspacesReturn {
  workspaces: WorkspaceWithFavorite[];
  loading: boolean;
  error: string | null;
  createWorkspace: (workspaceData: WorkspaceCreateData) => Promise<boolean>;
  refreshWorkspaces: () => Promise<void>;
  toggleFavorite: (workspaceId: string) => void;
}


export const useFetchWorkspaces = (): UseWorkspacesReturn => {

  const [workspaces, setWorkspaces] = useState<WorkspaceWithFavorite[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Load favorites from localStorage
  const loadFavorites = (): string[] => {
    const saved = localStorage.getItem(FAVORITES_KEY);
    return saved ? JSON.parse(saved) : [];
  };

  // Save favorites to localStorage
  const saveFavorites = (favorites: string[]) => {
    localStorage.setItem(FAVORITES_KEY, JSON.stringify(favorites));
  };

  const toggleFavorite = (workspaceId: string) => {
    const favorites = loadFavorites();
    const newFavorites = favorites.includes(workspaceId)
      ? favorites.filter((id) => id !== workspaceId)
      : [...favorites, workspaceId];

    saveFavorites(newFavorites);

    setWorkspaces((current) =>
      current
        .map((workspace) => ({
          ...workspace,
          isFavorite: newFavorites.includes(workspace.workspaceId),
        }))
        .sort((a, b) => {
          if (a.isFavorite === b.isFavorite) return a.name.localeCompare(b.name);
          return a.isFavorite ? -1 : 1;
        }),
    );
  };

  const fetchWorkspaces = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/user/workspaces`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: Workspace[] = await response.json();
      const favorites = loadFavorites();

      // Add favorite status and sort
      const workspacesWithFavorites = data
        .map((workspace) => ({
          ...workspace,
          isFavorite: favorites.includes(workspace.workspaceId),
        }))
        .sort((a, b) => {
          if (a.isFavorite === b.isFavorite) return a.name.localeCompare(b.name);
          return a.isFavorite ? -1 : 1;
        });

      setWorkspaces(workspacesWithFavorites);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  const createWorkspace = async (workspaceData: WorkspaceCreateData): Promise<boolean> => {
    try {
      setLoading(true);
      const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/workspace`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
        },
        body: JSON.stringify(workspaceData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      await fetchWorkspaces();
      return true;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  return {
    workspaces,
    loading,
    error,
    createWorkspace,
    refreshWorkspaces: fetchWorkspaces,
    toggleFavorite,
  };
};
