import { useState, useEffect } from 'react';
import { Terminal, Upload, Key, Command, AlertCircle, Menu, X } from 'lucide-react';

const sections = [
  {
    title: 'Getting Started',
    subsections: [
      { id: 'installation', title: 'Installation' },
      { id: 'authentication', title: 'Authentication' },
      { id: 'quickstart', title: 'Quick Start' },
    ],
  },
  {
    title: 'Core Concepts',
    subsections: [
      { id: 'builds', title: 'Builds' },
      { id: 'workspaces', title: 'Workspaces' },
      { id: 'projects', title: 'Projects' },
    ],
  },
  {
    title: 'CLI Tool',
    subsections: [
      { id: 'bctl', title: 'BCTL Usage' },
      { id: 'uploads', title: 'File Uploads' },
      { id: 'configuration', title: 'Configuration' },
    ],
  },
];

const SDKDocs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [activeSection, setActiveSection] = useState('installation');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const installation = `# Download the bctl binary for your platform
curl -O https://dl.buildeiro.com/bctl-latest
chmod +x bctl-latest
mv bctl-latest /usr/local/bin/bctl`;

  const authExample = `# Authenticate using client credentials
bctl -client-id your-team@company.com -client-secret your-secret -base-url https://api.buildeiro.com

# Token will be saved in .token file for future use`;

  const usageExample = `# Create and upload a build
bctl -workspace my-workspace \\
     -project my-project \\
     -build config.json \\
     -files "build.zip,assets.tar.gz" \\
     -base-url https://api.buildeiro.com`;

  const buildConfigExample = `{
  "version": "1.0.0",
  "platform": "windows",
  "platformArchitecture": "x64",
  "buildName": "release-candidate",
  "buildType": "release",
  "tags": ["stable", "production"],
  "releaseNotes": "New features and improvements"
}`;

  return (
    <div className="min-h-screen bg-white flex">
      {/* Mobile Menu Button */}
      <div className="md:hidden fixed top-4 left-4 z-50">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="p-2 bg-white rounded-lg border border-gray-300 hover:bg-gray-50 transition-colors"
        >
          {isMenuOpen ? <X className="w-6 h-6 text-gray-600" /> : <Menu className="w-6 h-6 text-gray-600" />}
        </button>
      </div>

      {/* Lateral Menu */}
      <aside
        className={`
          md:sticky top-0 h-screen flex-shrink-0 bg-white w-64 border-r border-gray-300
          fixed left-0 overflow-y-auto
          transform transition-transform duration-200 ease-in-out z-40
          ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}
          ${isMobile ? 'shadow-xl' : ''}
          md:translate-x-0
        `}
      >
        <div className="p-3">
          <h2 className="text-xl font-bold text-gray-700 mb-6">Documentation</h2>
          <nav className="space-y-6">
            {sections.map((section) => (
              <div key={section.title} className="space-y-2">
                <h3 className="text-sm font-medium text-gray-700 uppercase tracking-wider">
                  {section.title}
                </h3>
                <ul className="space-y-2">
                  {section.subsections.map((subsection) => (
                    <li key={subsection.id}>
                      <button
                        onClick={() => {
                          setActiveSection(subsection.id);
                          if (isMobile) setIsMenuOpen(false);
                        }}
                        className={`
                          w-full text-left px-2 py-1 rounded-lg text-sm transition-colors
                          ${
                            activeSection === subsection.id
                              ? 'bg-green-50 text-green-600 font-medium'
                              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                          }
                        `}
                      >
                        {subsection.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 min-w-0">
        <div className="max-w-7xl mx-auto px-6 py-12">
          <header className="mb-12">
            <h1 className="text-5xl font-bold text-gray-700 mb-2">Buildeiro CLI</h1>
            <p className="text-xl text-gray-600">
              Official command line tool for managing build uploads and releases
            </p>
          </header>

          <div className="space-y-12">
            {/* Quick Start */}
            <section id="quickstart">
              <h2 className="text-3xl font-bold text-gray-700 mb-6">Quick Start</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-50 rounded-xl p-3 hover:bg-gray-100 transition-colors">
                  <div className="w-16 h-16 bg-green-50 rounded-lg flex items-center justify-center mb-2">
                    <Terminal className="w-8 h-8 text-green-400" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-700 mb-2">Installation</h3>
                  <div className="bg-white rounded-lg p-4 border border-gray-300">
                    <pre className="text-gray-700 font-mono text-sm whitespace-pre-wrap overflow-x-auto">
                      {installation}
                    </pre>
                  </div>
                </div>

                <div className="bg-gray-50 rounded-xl p-3 hover:bg-gray-100 transition-colors">
                  <div className="w-16 h-16 bg-green-50 rounded-lg flex items-center justify-center mb-2">
                    <Key className="w-8 h-8 text-green-400" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-700 mb-2">Authentication</h3>
                  <div className="bg-white rounded-lg p-4 border border-gray-300">
                    <pre className="text-gray-700 font-mono text-sm whitespace-pre-wrap overflow-x-auto">
                      {authExample}
                    </pre>
                  </div>
                </div>
              </div>
            </section>

            {/* BCTL Usage */}
            <section id="bctl">
              <h2 className="text-3xl font-bold text-gray-700 mb-6">BCTL Command Line Tool</h2>
              <div className="space-y-6">
                <div className="bg-gray-50 rounded-xl p-3">
                  <h3 className="text-xl font-bold text-gray-700 mb-2">Basic Usage</h3>
                  <div className="bg-white rounded-lg p-4 border border-gray-300">
                    <pre className="text-gray-700 font-mono text-sm whitespace-pre-wrap overflow-x-auto">
                      {usageExample}
                    </pre>
                  </div>
                </div>

                <div className="bg-gray-50 rounded-xl p-3">
                  <h3 className="text-xl font-bold text-gray-700 mb-2">Build Configuration</h3>
                  <p className="text-gray-600 mb-2">Create a JSON file with your build configuration:</p>
                  <div className="bg-white rounded-lg p-4 border border-gray-300">
                    <pre className="text-gray-700 font-mono text-sm whitespace-pre-wrap overflow-x-auto">
                      {buildConfigExample}
                    </pre>
                  </div>
                </div>
              </div>
            </section>

            {/* Features */}
            <section id="features">
              <h2 className="text-3xl font-bold text-gray-700 mb-6">Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                {[
                  {
                    icon: <Upload className="w-8 h-8 text-green-400" />,
                    title: 'Git Integration',
                    description:
                      'Automatically extracts commit hash, branch name, and release notes from git repository',
                  },
                  {
                    icon: <Command className="w-8 h-8 text-green-400" />,
                    title: 'Multiple File Upload',
                    description:
                      'Upload multiple build artifacts in a single command with automatic size calculation',
                  },
                  {
                    icon: <AlertCircle className="w-8 h-8 text-green-400" />,
                    title: 'Secure Authentication',
                    description:
                      'OAuth2 client credentials flow with token caching for subsequent operations',
                  },
                ].map((feature, index) => (
                  <div key={index} className="bg-gray-50 rounded-xl p-3 hover:bg-gray-100 transition-colors">
                    <div className="w-16 h-16 bg-green-50 rounded-lg flex items-center justify-center mb-2">
                      {feature.icon}
                    </div>
                    <h3 className="text-xl font-bold text-gray-700 mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                ))}
              </div>
            </section>

            {/* Configuration */}
            <section id="configuration">
              <h2 className="text-3xl font-bold text-gray-700 mb-6">Configuration</h2>
              <div className="bg-gray-50 rounded-xl p-3">
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 font-bold text-gray-700">Option</th>
                        <th className="text-left py-4 font-bold text-gray-700">Description</th>
                        <th className="text-left py-4 font-bold text-gray-700">Default</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300">
                      <tr>
                        <td className="py-4 font-mono text-gray-700">-base-url</td>
                        <td className="py-4 text-gray-600">API endpoint URL</td>
                        <td className="py-4 text-gray-600">http://localhost:8080</td>
                      </tr>
                      <tr>
                        <td className="py-4 font-mono text-gray-700">-workspace</td>
                        <td className="py-4 text-gray-600">Workspace identifier</td>
                        <td className="py-4 text-gray-600">Required</td>
                      </tr>
                      <tr>
                        <td className="py-4 font-mono text-gray-700">-project</td>
                        <td className="py-4 text-gray-600">Project identifier</td>
                        <td className="py-4 text-gray-600">Required</td>
                      </tr>
                      <tr>
                        <td className="py-4 font-mono text-gray-700">-client-id</td>
                        <td className="py-4 text-gray-600">OAuth client ID</td>
                        <td className="py-4 text-gray-600">Required for auth</td>
                      </tr>
                      <tr>
                        <td className="py-4 font-mono text-gray-700">-client-secret</td>
                        <td className="py-4 text-gray-600">OAuth client secret</td>
                        <td className="py-4 text-gray-600">Required for auth</td>
                      </tr>
                      <tr>
                        <td className="py-4 font-mono text-gray-700">-build</td>
                        <td className="py-4 text-gray-600">Path to build config JSON</td>
                        <td className="py-4 text-gray-600">Required</td>
                      </tr>
                      <tr>
                        <td className="py-4 font-mono text-gray-700">-files</td>
                        <td className="py-4 text-gray-600">Comma-separated list of files</td>
                        <td className="py-4 text-gray-600">Required</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SDKDocs;