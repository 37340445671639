import { PlatformType } from '@/models/platformType';
import { BuildType } from '@/models/buildType';

export interface BuildDetails {
  buildId?: string;
  version: string;
  buildName: string;
  platform: PlatformType;
  platformArchitecture: string;
  buildType: BuildType;
  commitHash: string;
  branchName: string;
  buildSize: number;
  releaseNotes: string;
  ciUrl: string;
  tags: string[];
  createdAt?: string;
  updatedAt?: string;
}
export const defaultBuildDetails: BuildDetails = {
  version: '',
  buildName: '',
  platform: 'OTHER',
  platformArchitecture: '',
  buildType: 'CI',
  commitHash: '',
  branchName: '',
  buildSize: 0,
  releaseNotes: '',
  ciUrl: '',
  tags: [],
};
