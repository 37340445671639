import React, { useState, ReactNode, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Bell,
  ChevronDown,
  LogOut,
  Search,
  Settings,
  User,
  LucideIcon,
  Menu,
  X,
} from 'lucide-react';
import { TokenService } from '@/lib/token';
import { Footer } from './Footer';
import { useAuth } from '@/hooks/useAuth';


// Interfaces
interface NavItem {
  label: string;
  requiresAuth: boolean;
  hiddenOnAuth?: boolean;
  href: string;
}

interface LayoutProps {
  children: ReactNode;
}

interface UserMenuItem {
  label: string;
  icon: LucideIcon;
  href: string;
  onClick?: () => void;
  variant?: 'default' | 'danger';
}


// Navigation Component
export const Navigation: React.FC = () => {
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const tokenService = TokenService.getInstance();

  const navItems: NavItem[] = [
    { label: 'Workspaces', requiresAuth: true, href: '/workspace' },
    { label: 'Pricing', requiresAuth: false, href: '/pricing', hiddenOnAuth: true },
    { label: 'Documentation', requiresAuth: false, href: '/documentation' },
  ];

  const filteredNavItems = navItems.filter((item) => {
    // Show items that require auth only when authenticated
    if (item.requiresAuth && !isAuthenticated) return false;
    // Hide items with hiddenOnAuth when authenticated
    if (item.hiddenOnAuth && isAuthenticated) return false;
    return true;
  });

  const actionIcons: ('Bell' | 'Settings')[] = [];

  const userMenuItems: UserMenuItem[] = [
    {
      label: 'Profile',
      icon: User,
      href: '/profile',
    },
    {
      label: 'Sign Out',
      icon: LogOut,
      href: '/login',
      variant: 'danger',
      onClick: () => {
        tokenService.clearTokens();
        navigate('/login');
      },
    },
  ];

  const handleUserMenuClick = (item: UserMenuItem) => {
    if (item.onClick) {
      item.onClick();
    } else {
      navigate(item.href);
    }
    setShowUserMenu(false);
    setShowMobileMenu(false);
  };

  useEffect(() => {
    return () => setShowMobileMenu(false);
  }, [location]);

  return (
    <header className="sticky top-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-200 z-[49]">
      <nav className="max-w-0xl mx-auto px-6" aria-label="Main Navigation">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center gap-8">
            <Link to="/" className="flex flex-col items-start">
              <span className="font-bold text-gray-700">
                Releases<span className="text-green-400">.</span>
              </span>
              <span className="text-xs text-gray-400">by pushbuild</span>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-3">
              {filteredNavItems.map((item) => (
                <button
                  key={item.label}
                  onClick={() => navigate(item.href)}
                  className="text-gray-600 hover:text-gray-900 flex items-center gap-1 transition-colors"
                >
                  {item.label}
                  <ChevronDown className="w-4 h-4" aria-hidden="true" />
                </button>
              ))}
            </div>
          </div>

          {/* Rest of the navigation component remains the same... */}
          <div className="flex items-center gap-4">
            {isAuthenticated ? (
              <>
                <div className="hidden md:flex items-center">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="w-64 px-2 py-2 pr-10 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-300 focus:border-green-400"
                    />
                    <Search
                      className="absolute right-3 top-2.5 w-4 h-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>

                <div className="hidden md:flex items-center gap-2">
                  {actionIcons.map((iconName, index) => {
                    const IconComponent = iconName === 'Bell' ? Bell : Settings;
                    return (
                      <button
                        key={index}
                        className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors"
                        aria-label={iconName}
                      >
                        <IconComponent className="w-5 h-5" />
                      </button>
                    );
                  })}
                </div>

                <div className="hidden md:block relative">
                  <button
                    onClick={() => setShowUserMenu(!showUserMenu)}
                    className="flex items-center gap-2 p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors"
                    aria-haspopup="true"
                    aria-expanded={showUserMenu}
                  >
                    <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center">
                      <span className="text-sm font-medium text-green-600">JD</span>
                    </div>
                    <ChevronDown className="w-4 h-4" aria-hidden="true" />
                  </button>

                  {showUserMenu && (
                    <div
                      className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200"
                      role="menu"
                      aria-label="User menu"
                    >
                      <div className="py-2">
                        {userMenuItems.map((item) => (
                          <button
                            key={item.label}
                            onClick={() => handleUserMenuClick(item)}
                            className={`w-full px-2 py-2 text-sm text-left flex items-center gap-2 hover:bg-gray-50 transition-colors
                              ${
                                item.variant === 'danger'
                                  ? 'text-red-600 hover:text-red-700'
                                  : 'text-gray-600 hover:text-gray-900'
                              }
                            `}
                            role="menuitem"
                          >
                            <item.icon className="w-4 h-4" aria-hidden="true" />
                            {item.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <button
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                  className="md:hidden p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors"
                  aria-expanded={showMobileMenu}
                  aria-label="Toggle mobile menu"
                >
                  {showMobileMenu ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                </button>
              </>
            ) : (
              <div className="flex items-center gap-4">
                <Link to="/login" className="text-gray-600 hover:text-gray-900 transition-colors">
                  Sign In
                </Link>
                <Link
                  to="/signup"
                  className="px-6 py-2 bg-green-400 text-white rounded-lg hover:bg-green-500 transition-all duration-150 ease-in-out"
                >
                  Get Started
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {showMobileMenu && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <div className="p-2">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full px-2 py-2 pr-10 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-300 focus:border-green-400"
                  />
                  <Search
                    className="absolute right-3 top-2.5 w-4 h-4 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>

              {filteredNavItems.map((item) => (
                <button
                  key={item.label}
                  onClick={() => {
                    navigate(item.href);
                    setShowMobileMenu(false);
                  }}
                  className="w-full px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg flex items-center justify-between"
                >
                  {item.label}
                  <ChevronDown className="w-4 h-4" aria-hidden="true" />
                </button>
              ))}

              <div className="flex items-center gap-2 p-2">
                {actionIcons.map((iconName, index) => {
                  const IconComponent = iconName === 'Bell' ? Bell : Settings;
                  return (
                    <button
                      key={index}
                      className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors"
                      aria-label={iconName}
                    >
                      <IconComponent className="w-5 h-5" />
                    </button>
                  );
                })}
              </div>

              <div className="border-t border-gray-200 pt-4">
                {userMenuItems.map((item) => (
                  <button
                    key={item.label}
                    onClick={() => handleUserMenuClick(item)}
                    className={`w-full px-3 py-2 text-base font-medium flex items-center gap-2 hover:bg-gray-50 transition-colors
                      ${
                        item.variant === 'danger'
                          ? 'text-red-600 hover:text-red-700'
                          : 'text-gray-600 hover:text-gray-900'
                      }
                    `}
                  >
                    <item.icon className="w-4 h-4" aria-hidden="true" />
                    {item.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
          body {
            font-family: 'Inter', sans-serif;
          }
        `}
      </style>

      <Navigation />
      <main className="flex-grow bg-gray-50">{children}</main>
      <Footer />
    </div>
  );
};

export default { Layout, Navigation };