import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Build } from "@/models/build";
import LoadingSpinner from "@/ui/LoadingSpinner/LoadingSpinner";
import { GitBranch, Package, Monitor, Server, Tag, Code, Archive, Globe, Calendar } from "lucide-react";
import { useBuildFiles } from "./hooks/useBuildFiles";
import ErrorAlert from "@/ui/Errors/ErrorAlert/ErrorAlert";
import { BuildFileCard } from "./BuildFileList";
import PublicLinks from "./PublicLinkModal";
import { useFormatters } from '@/hooks/useFormatters';
import { ProjectWithFavorite } from '@/models/project';
import { Permissions } from '@/models/permission';

interface BuildManagerProps {
    build: Build;
    workspaceSlug: string;
    projectSlug: string;
    project: ProjectWithFavorite;
}

interface BuildFilesProps {
    workspaceSlug: string;
    projectSlug: string;
    build: Build;
    onShare: () => void;
}

const BuildFiles: React.FC<BuildFilesProps> = ({
    workspaceSlug,
    projectSlug,
    build,
    onShare
}) => {
    const { files, isLoading, error } = useBuildFiles({
        workspaceSlug,
        projectSlug,
        buildId: build.buildId
    });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <ErrorAlert message="Could not load build files" />;
    }

    return (
        <>
            {files.map((file) => (
                <BuildFileCard
                    key={file.fileId}
                    file={file}
                    workspaceSlug={workspaceSlug}
                    projectSlug={projectSlug}
                    actions={{
                        onDownload: () => { },
                        onShare
                    }}
                />
            ))}
        </>
    );
}

const DetailItem = ({ icon: Icon, label, value }: { icon: any, label: string, value?: string | number | null }) => (
    <div className="flex flex-col items-center justify-center p-4 space-y-2 border rounded-lg">
        <p className="text-sm font-medium text-gray-700">{label}</p>
        <div className="flex items-center space-x-2">
            <Icon className="w-4 h-4 text-gray-700" />
            <span className="text-sm">{value || '-'}</span>
        </div>
    </div>
);

const BuildDetails = ({ build, workspaceSlug, projectSlug, project }: BuildManagerProps) => {

    const { formatDate, formatFileSize } = useFormatters();


    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                    <Monitor className="w-5 h-5 text-blue-500" />
                    <div>
                        <h3 className="text-lg font-semibold">{build.buildName || 'Unnamed Build'}</h3>
                        <p className="text-sm text-gray-700">{build.version || '-'}</p>
                    </div>
                </div>
            </div>

            <Tabs defaultValue="details">
                <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="details">Details</TabsTrigger>
                    <TabsTrigger value="files">Files</TabsTrigger>
                    <TabsTrigger value="distribution">Distribution</TabsTrigger>
                </TabsList>

                <TabsContent value="files" className="space-y-4">
                    <BuildFiles
                        workspaceSlug={workspaceSlug}
                        projectSlug={projectSlug}
                        build={build}
                        onShare={() => { }}
                    />
                </TabsContent>

                <TabsContent value="details">
                    <div className="space-y-6">
                        <div className="grid grid-cols-3 gap-4">
                            <DetailItem
                                icon={Package}
                                label="Build Identifier"
                                value={build.buildId}
                            />
                            <DetailItem
                                icon={GitBranch}
                                label="Git Branch Name"
                                value={build.branchName}
                            />
                            <DetailItem
                                icon={Code}
                                label="Git Commit Hash"
                                value={build.commitHash}
                            />
                            <DetailItem
                                icon={Server}
                                label="Target Platform"
                                value={build.platform}
                            />
                            <DetailItem
                                icon={Package}
                                label="Platform Architecture"
                                value={build.platformArchitecture}
                            />
                            <DetailItem
                                icon={Tag}
                                label="Build Type"
                                value={build.buildType}
                            />
                            <DetailItem
                                icon={Archive}
                                label="Total Build Size"
                                value={formatFileSize(build.buildSize)}
                            />
                            <DetailItem
                                icon={Globe}
                                label="CI/CD Pipeline URL"
                                value={build.ciUrl}
                            />
                            <DetailItem
                                icon={Tag}
                                label="Build Tags"
                                value={build.tags?.join(', ')}
                            />
                            <DetailItem
                                icon={Calendar}
                                label="Creation Date"
                                value={formatDate(build.createdAt)}
                            />
                            <DetailItem
                                icon={Calendar}
                                label="Last Updated"
                                value={formatDate(build.updatedAt)}
                            />
                        </div>

                        <div className="space-y-4">
                            <div className="p-4 border rounded-lg">
                                <h4 className="text-sm font-medium text-gray-700 mb-2">Release Notes</h4>
                                <p className="text-sm text-gray-700">{build.releaseNotes || '-'}</p>
                            </div>
                        </div>
                    </div>
                </TabsContent>

                { project && project.permissions.includes(Permissions.BuildDownload) && <TabsContent value="distribution">
                    <div className="space-y-6">
                        <PublicLinks
                            build={build}
                            projectSlug={projectSlug}
                            workspaceSlug={workspaceSlug}
                            editable={project.permissions.includes(Permissions.BuildCreatePublicLinks)}
                        />
                    </div>
                </TabsContent> }
            </Tabs>
        </div>
    );
};

export const BuildManager = ({ build, workspaceSlug, projectSlug, project }: BuildManagerProps): JSX.Element => {
    return <BuildDetails build={build} workspaceSlug={workspaceSlug} projectSlug={projectSlug} project={project}/>;
}