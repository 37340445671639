// hooks/useProjectDelete.ts
import { useState } from 'react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { ProjectWithFavorite } from '../../../models/project';

interface UseProjectDeleteProps {
  workspaceSlug: string;
  onSuccess: () => void;
}

export const useProjectDelete = ({ workspaceSlug, onSuccess }: UseProjectDeleteProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectWithFavorite | null>(null);
  const [isDeleting, setDeleting] = useState(false);

  const handleDeleteClick = (project: ProjectWithFavorite) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!selectedProject) return;

    setDeleting(true);
    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${selectedProject.projectSlug}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
        }
      );

      if (response.ok) {
        setIsModalOpen(false);
        setSelectedProject(null);
        onSuccess();
      }
    } catch (error) {
      console.error('Failed to delete project:', error);
    } finally {
      setDeleting(false);
    }
  };

  return {
    isModalOpen,
    setIsModalOpen,
    selectedProject,
    isDeleting,
    handleDeleteClick,
    handleDelete
  };
};