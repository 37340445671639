import { useState } from 'react';
import {
  Plus, Users, FolderGit2, HardDrive, Settings, Star,
  Loader2
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/ui/table';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { WorkspaceWithFavorite } from '@/models/workdpace';
import { useFetchWorkspaces } from '@/hooks/useWorkspaces';
import SlideOver from '@/ui/SlideOver/SlideOver';
import { SlideOverType } from '@/ui/SlideOver/SlideOverType';
import ManageWorkspace from './ManageWorkspace';
import { useSlideOver } from '@/ui/SlideOver/hooks/useSlideOver';

interface WorkspaceTableProps {
  workspaces: WorkspaceWithFavorite[];
  onToggleFavorite: (workspaceId: string) => void;
}

const WorkspaceTable = ({ workspaces, onToggleFavorite }: WorkspaceTableProps) => {
  const navigate = useNavigate();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-12"></TableHead>
          <TableHead className="text-gray-700">Name</TableHead>
          <TableHead className="text-gray-700">Storage</TableHead>
          <TableHead className="text-gray-700">Members</TableHead>
          <TableHead className="text-gray-700">Projects</TableHead>
          <TableHead className="w-12"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {workspaces.map(workspace => (
          <TableRow key={workspace.workspaceId}>
            <TableCell>
              <Button variant="ghost" size="icon" onClick={() => onToggleFavorite(workspace.workspaceId)}>
                <Star className={workspace.isFavorite ? "fill-green-400 text-green-400" : "text-gray-300"} />
              </Button>
            </TableCell>
            <TableCell className="font-medium text-gray-700">{workspace.name}</TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <HardDrive className="w-4 h-4 text-gray-700" />
                <span className="text-gray-600">{(workspace.maxStorage / 1024 / 1024 / 1024).toFixed(0)} GB</span>
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <Users className="w-4 h-4 text-gray-700" />
                <span className="text-gray-600">{workspace.maxUsers}</span>
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <FolderGit2 className="w-4 h-4 text-gray-700" />
                <span className="text-gray-600">{workspace.maxProjects}</span>
              </div>
            </TableCell>
            <TableCell>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Settings className="w-4 h-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={() => navigate(`/workspace/${workspace.slug}/projects`)}>
                    View Projects
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => navigate(`/workspace/${workspace.slug}/membership`)}>
                    Manage Members
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const Dashboard = () => {
  const { workspaces, loading, error, toggleFavorite, refreshWorkspaces } = useFetchWorkspaces();
  const { setActivePanel } = useSlideOver();
  const [searchQuery, setSearchQuery] = useState('');

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 text-green-400 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="bg-red-50 text-red-700 px-3 py-2 rounded-lg">
          <p className="text-sm">Error: {error}</p>
        </div>
      </div>
    );
  }

  const filteredWorkspaces = workspaces.filter(workspace =>
    workspace.name.toLowerCase().includes(searchQuery.toLowerCase())
  );



  return (
    <div className="min-h-screen bg-gray-50">
      <div className="border-b bg-white/80 backdrop-blur sticky top-0">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex items-center justify-between h-16">
            <h1 className="text-xl font-semibold text-gray-700">Workspaces</h1>
            <div className="flex items-center gap-4">
              <div className="w-64">
                <Input
                  placeholder="Search workspaces..."
                  className="h-8"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <Button
                onClick={() => setActivePanel(SlideOverType.WORKSPACE_CREATE)}
                className="bg-green-400 text-white hover:bg-green-500"
              >
                <Plus className="w-4 h-4 mr-2" />
                New Workspace
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-3 py-2">

        <Tabs defaultValue="all" className="space-y-3">
          <div className="flex justify-between items-center">
            <TabsList>
              <TabsTrigger value="all">All Workspaces</TabsTrigger>
              <TabsTrigger value="favorites">Favorites</TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value="all" className="m-0">
            <Card>
              <CardContent className="p-3">
                <WorkspaceTable
                  workspaces={filteredWorkspaces}
                  onToggleFavorite={toggleFavorite}
                />
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="favorites" className="m-0">
            <Card>
              <CardContent className="p-3">
                <WorkspaceTable
                  workspaces={filteredWorkspaces.filter(w => w.isFavorite)}
                  onToggleFavorite={toggleFavorite}
                />
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>

      <SlideOver title='Create Workspace' type={SlideOverType.WORKSPACE_CREATE} width='w-450'>
        <ManageWorkspace onUpdate={refreshWorkspaces} />
      </SlideOver>
    </div>
  );
};

export default Dashboard;