import { Dispatch, SetStateAction, useEffect, useState } from "react";

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * A custom hook that persists state in localStorage
 * @template T - The type of the state value
 * @param {string} key - The key to store the value under in localStorage
 * @param {T} initialValue - The initial value if none is found in localStorage
 * @returns {[T, SetValue<T>]} A tuple containing the current value and a setter function
 */
export function usePersistentState<T>(key: string, initialValue: T): [T, SetValue<T>] {
  // Get initial state from localStorage or use provided initialValue
  const [state, setState] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  // Update localStorage when state changes
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  }, [key, state]);

  return [state, setState];
}
