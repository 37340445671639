export enum Permissions {
    // Organization permissions
    WorkspaceCreate = "organization.workspace.create",
    WorkspaceRead = "organization.workspace.read",
    WorkspaceEdit = "organization.workspace.edit",
    WorkspaceDelete = "organization.workspace.delete",
  
    // Project permissions
    ProjectCreate = "organization.project.create",
    ProjectRead = "organization.project.read",
    ProjectEdit = "organization.project.edit",
    ProjectDelete = "organization.project.delete",
  
    // Build permissions
    BuildUpload = "organization.build.upload",
    BuildDownload = "organization.build.download",
    BuildCreatePublicLinks = "organization.build.createPublicLinks",
    BuildCreate = "organization.build.create",
    BuildEdit = "organization.build.edit",
    BuildDelete = "organization.build.delete"
}